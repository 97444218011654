<template>
    <div class="login-page">
        <div class="login-container">
            <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
                    <div style="color: transparent"></div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-form
                        ref="form"
                        :model="form"
                        class="login-form"
                        label-position="left"
                    >
                        <div class="title">前台登录</div>
                        <div class="title-tips">欢迎来到{{ title }}！</div>
                        <!-- <p style="padding:4px;background-color:white;color:red;margin-top:10px;font-weight:bold;font-size:20px;">各位用户：您现在登录的是抢先体验版，本版本仅供抢先体验功能使用，非线上正式运营版本。</p> -->
                        <el-form-item class="global-mt-10" prop="company">
                            <span
                                class="iconfont icon-shangjia login-icon"
                            ></span>
                            <el-input
                                v-model.trim="form.company"
                                placeholder="请输入商户名称"
                                tabindex="1"
                                type="text"
                                @keyup.enter.native="handleLogin"
                            />
                        </el-form-item>
                        <el-form-item class="global-mt-10" prop="account">
                            <span
                                class="iconfont icon-zhanghao login-icon"
                            ></span>
                            <el-input
                                v-model.trim="form.account"
                                placeholder="请输入账号名"
                                tabindex="1"
                                type="text"
                                @keyup.enter.native="handleLogin"
                            />
                        </el-form-item>
                        <el-form-item prop="password">
                            <span class="iconfont icon-mima login-icon"></span>
                            <el-input
                                ref="password"
                                v-model.trim="form.password"
                                type="password"
                                tabindex="2"
                                placeholder="请输入密码"
                                @keyup.enter.native="handleLogin"
                            />
                        </el-form-item>
                        <el-button
                            :loading="loading"
                            class="login-btn"
                            type="primary"
                            @click="handleLogin"
                            >登录</el-button
                        >
                        <!-- <router-link to="/register">
                            <div style="margin-top: 20px">注册</div>
                        </router-link> -->
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { ElMessage } from "element-plus";
import { loginApi } from "@/api";
import * as types from "@/store/types";
export default {
    name: "login-page",
    data() {
        return {
            title: "商家前台",
            form: {
                company: "",
                account: "",
                password: "",
            },
            redirect: "",
        };
    },
    watch: {
        $route: {
            handler(route) {
                console.log("route.query", route.query);
                this.redirect = (route.query && route.query.redirect) || "/";
            },
            immediate: true,
        },
    },
    created() {
        // document.body.style.overflow = 'hidden'
    },
    beforeDestroy() {
        // document.body.style.overflow = 'auto'
    },
    methods: {
        handleLogin() {
            if (!this.form.company.trim()) {
                ElMessage.warning({
                    message: "请输入商户名称",
                    type: "warning",
                });
                return;
            } else if (!this.form.account.trim()) {
                ElMessage.warning({
                    message: "请输入账号",
                    type: "warning",
                });
                return;
            } else if (!this.form.password.trim()) {
                ElMessage.warning({
                    message: "请输入密码",
                    type: "warning",
                });
                return;
            }
            loginApi.login(this.form).then((res) => {
                this.$store.commit(types.SET_USERDATA, res.data);
                loginApi.merchantSetIndex().then((res) => {
                    this.$store.commit(types.SET_MERCHANT_INFO, res.data);
                    this.$router.push({
                        path: this.redirect,
                    });
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.login-page {
    .login-container {
        height: 100vh;
        background: url("~@/assets/images/login/bg.jpg") center center fixed
            no-repeat;
        background-size: cover;
        .login-icon {
            position: absolute;
            top: 1px;
            bottom: 0;
            left: 18px;
            color: black;
            z-index: 1;
        }
        .title {
            font-size: 30px;
            font-weight: 500;
            // color: rgba(14, 18, 26, 1);
            color: white;
        }

        .title-tips {
            margin-top: 29px;
            font-size: 18px;
            font-weight: 400;
            // color: rgba(14, 18, 26, 1);
            color: white;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .login-btn {
            display: inherit;
            width: 150px;
            height: 40px;
            margin-top: 5px;
            border: 0;

            &:hover {
                opacity: 0.9;
            }
        }

        .login-form {
            position: relative;
            max-width: 100%;
            margin: calc((100vh - 425px) / 2) 10% 10%;
            padding: 50px;
            overflow: hidden;
            width: 300px;
            background: url("~@/assets/images/login/login_form.ad34f1fd.png");
            background-size: 100% 100%;
            .forget-password {
                width: 100%;
                margin-top: 40px;
                text-align: left;

                .forget-pass {
                    width: 129px;
                    height: 19px;
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(92, 102, 240, 1);
                }
            }
        }

        .tips {
            margin-bottom: 10px;
            //   font-size: $base-font-size-default;
            //   color: $base-color-white;

            span {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        .title-container {
            position: relative;

            .title {
                margin: 0 auto 40px auto;
                font-size: 34px;
                font-weight: bold;
                // color: $base-color-blue;
                text-align: center;
            }
        }

        .svg-container {
            position: absolute;
            top: 14px;
            left: 15px;
            //   z-index: $base-z-index;
            font-size: 16px;
            color: #d7dee3;
            cursor: pointer;
            user-select: none;
        }

        .show-password {
            position: absolute;
            top: 14px;
            right: 25px;
            font-size: 16px;
            color: #d7dee3;
            cursor: pointer;
            user-select: none;
        }

        ::v-deep {
            .el-form-item {
                padding-right: 0;
                margin: 20px 0;
                color: #454545;
                background: transparent;
                border: 1px solid transparent;
                border-radius: 2px;

                &__content {
                    //   min-height: $base-input-height;
                    //   line-height: $base-input-height;
                }

                &__error {
                    position: absolute;
                    top: 100%;
                    left: 18px;
                    //   font-size: $base-font-size-small;
                    line-height: 18px;
                    //   color: $base-color-red;
                }
            }

            .el-input {
                box-sizing: border-box;

                input {
                    height: 40px;
                    padding-left: 45px;
                    //   font-size: $base-font-size-default;
                    line-height: 40px;
                    //   color: $base-font-color;
                    background: #f6f4fc;
                    border: 0;
                    //   caret-color: $base-font-color;
                }
            }
        }
    }
}
</style>